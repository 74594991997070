/*------------------------------------------------------------------
Project:  ComingSoon
Version:  
Last change:  
Assigned to:  Bach Le
Primary use:  
-------------------------------------------------------------------*/



/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

@font-face {
  font-family: Montserrat-Regular;
  src: url('../fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: Montserrat-Thin;
  src: url('../fonts/Montserrat/Montserrat-Thin.ttf');
}

@font-face {
  font-family: Montserrat-Bold;
  src: url('../fonts/Montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: Quantico-Regular;
  src: url('../fonts/Quantico/Quantico-Regular.ttf');
}


/*//////////////////////////////////////////////////////////////////
[ RS PLUGIN ]*/
/*---------------------------------------------*/
.container {
  max-width: 1200px;
}


/*//////////////////////////////////////////////////////////////////
[ RS Flip Clock ]*/
.flip-clock-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  max-width: 100%;
  margin: 0;
  margin-bottom: 20px;
}

.flip-clock-wrapper .flip {
  box-shadow: none;
  margin-bottom: 80px;
}

.flip-clock-wrapper ul {
  position: relative;
  float: unset;
  margin: 3px;
  width: 90px;
  height: 120px;
  font-size: 36px;
  font-weight: unset;
  line-height: unset;
  border-radius: 10px;
  background: #0d1629;
}

.flip-clock-wrapper ul li {
  line-height: unset;
}

.flip-clock-wrapper ul li a div {
  outline: none;
}

.flip-clock-wrapper ul li a div div.inn {
  font-family: Quantico-Regular;
  color: #fff;
  font-size: 120px;
  font-weight: unset;
  text-align: unset;

  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 200%;
  text-shadow: 0 0px 0px #000;
  background-color: #0d1629;
  border-radius: 10px;
}

.flip-clock-wrapper ul li a div .shadow {
  border-radius: 10px;
}

.flip-clock-wrapper ul li a div.up:after {
  display: none;
}

/*---------------------------------------------*/
.flip-clock-divider {
  display: block;
  float: unset;
  position: relative;
  width: 68px;
  height: 120px;
}

.flip-clock-divider span {
  display: none;
}

.flip-clock-divider::before {
  content: ":";
  font-family: Quantico-Regular;
  color: #fff;
  font-size: 120px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.flip-clock-divider.days::before {
  display: none;
}

.flip-clock-divider::after {
  font-family: Montserrat-Bold;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 3px;

  display: block;
  text-align: center;
  width: 193px;
  position: absolute;
  left: 100%;
  top: 100%;
  padding-top: 23px;
}

.flip-clock-divider.days::after {
  content: "days";
}

.flip-clock-divider.hours::after {
  content: "hrs";
}

.flip-clock-divider.minutes::after {
  content: "min";
}

.flip-clock-divider.seconds::after {
  content: "sec";
}

@media (max-width: 1200px) {
  .flip-clock-wrapper {
    max-width: 470px;
    margin-right: 10px;
  }

  .flip-clock-divider.days {
    width: 10px;
  }


  .flip-clock-divider.minutes {
    width: 10px;
  }

  .flip-clock-divider.minutes::before {
    display: none;
  }
}

@media (max-width: 576px) {
  .flip-clock-wrapper {
    max-width: 210px;
    margin-right: 10px;
  }

  .flip-clock-divider {
    width: 10px;
  }

  .flip-clock-divider::before {
    display: none;
  }
}


/*==================================================================
    TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT TEXT
==================================================================*/

/*==================================================================
[ Color ]*/
.cl0 {
  color: #fff;
}




/*//////////////////////////////////////////////////////////////////
[ S-Text 0 - 15 ]*/
.s1-txt1 {
  font-family: Montserrat-Bold;
  font-size: 14px;
  color: #fff;
  line-height: 1;
  text-transform: uppercase;
}

/*//////////////////////////////////////////////////////////////////
[ M-Text 16 - 25 ]*/




/*//////////////////////////////////////////////////////////////////
[ L-Text >= 26 ]*/
.l1-txt1 {
  font-family: Montserrat-Thin;
  font-size: 50px;
  color: #000;
  line-height: 1.1;
  text-transform: uppercase;
  letter-spacing: 10px;
}


/*==================================================================
   SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE SHAPE
==================================================================*/
/*//////////////////////////////////////////////////////////////////
[ Size ]*/
.size1 {
  width: 100%;
  min-height: 100vh;
}

.size2 {
  width: 100%;
  height: 50px;
}

.size3 {
  width: 36px;
  height: 36px;
}


/*//////////////////////////////////////////////////////////////////
[ Width ]*/
.wsize1 {
  width: 390px;
  max-width: 100%;
}

/*//////////////////////////////////////////////////////////////////
[ Height ]*/
.hsize1 {
  min-height: 500px;
}



/*//////////////////////////////////////////////////////////////////
[ Background ]*/
.bg0 {
  background-color: #fff;
}

.bg-img1 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}




/*//////////////////////////////////////////////////////////////////
[ Border ]*/
.bor1 {
  border-radius: 10px;
}




/*==================================================================
   WHERE WHERE WHERE WHERE WHERE WHERE WHERE WHERE WHERE WHERE WHERE  
==================================================================*/




/*==================================================================
 HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW HOW 
==================================================================*/
.placeholder0::-webkit-input-placeholder {
  color: #999999;
}

.placeholder0:-moz-placeholder {
  color: #999999;
}

.placeholder0::-moz-placeholder {
  color: #999999;
}

.placeholder0:-ms-input-placeholder {
  color: #999999;
}

/*---------------------------------------------*/
.overlay1 {
  position: relative;
  z-index: 1;
}

.overlay1::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #7579ff;
  background: -webkit-linear-gradient(to top, #2ca16c, #93FECE);
  background: -o-linear-gradient(to top, #2ca16c, #93FECE);
  background: -moz-linear-gradient(to top, #2ca16c, #93FECE);
  background: linear-gradient(to top, #2ca16c, #93FECE);
  opacity: 0.8;
}


/*---------------------------------------------*/
.wrappic1 {
  display: block;
  flex-grow: 1;
}

.wrappic1 img {
  max-width: 100%;
}

/*---------------------------------------------*/
.how-social {
  color: #fff;
  font-size: 18px;
  border: 1px solid #fff;
  background-color: transparent;
  border-radius: 50%;
}

.how-social:hover {
  background-color: #fff;
  color: #0d1629;
}


/*//////////////////////////////////////////////////////////////////
[ Pseudo ]*/

/*------------------------------------------------------------------
[ Focus ]*/
.focus-in0:focus::-webkit-input-placeholder {
  color: transparent;
}

.focus-in0:focus:-moz-placeholder {
  color: transparent;
}

.focus-in0:focus::-moz-placeholder {
  color: transparent;
}

.focus-in0:focus:-ms-input-placeholder {
  color: transparent;
}


/*------------------------------------------------------------------
[ Hover ]*/
.hov-cl0:hover {
  color: #fff;
}

.hov-bg0:hover {
  background-color: #fff;
}

/*---------------------------------------------*/







/*==================================================================
  RESPONSIVE RESPONSIVE RESPONSIVE RESPONSIVE RESPONSIVE RESPONSIVE
==================================================================*/
/*//////////////////////////////////////////////////////////////////
[ XXL ]*/


/*//////////////////////////////////////////////////////////////////
[ XL ]*/
@media (max-width: 1200px) {
  .m-0-xl {
    margin: 0;
  }

  .m-lr-0-xl {
    margin-left: 0;
    margin-right: 0;
  }

  .m-lr-15-xl {
    margin-left: 15px;
    margin-right: 15px;
  }

  .m-l-0-xl {
    margin-left: 0;
  }

  .m-r-0-xl {
    margin-right: 0;
  }

  .m-l-15-xl {
    margin-left: 15px;
  }

  .m-r-15-xl {
    margin-right: 15px;
  }

  .p-0-xl {
    padding: 0;
  }

  .p-lr-0-xl {
    padding-left: 0;
    padding-right: 0;
  }

  .p-lr-15-xl {
    padding-left: 15px;
    padding-right: 15px;
  }

  .p-l-0-xl {
    padding-left: 0;
  }

  .p-r-0-xl {
    padding-right: 0;
  }

  .p-l-15-xl {
    padding-left: 15px;
  }

  .p-r-15-xl {
    padding-right: 15px;
  }

  .w-full-xl {
    width: 100%;
  }

  /*---------------------------------------------*/

}


/*//////////////////////////////////////////////////////////////////
[ LG ]*/
@media (max-width: 992px) {
  .m-0-lg {
    margin: 0;
  }

  .m-lr-0-lg {
    margin-left: 0;
    margin-right: 0;
  }

  .m-lr-15-lg {
    margin-left: 15px;
    margin-right: 15px;
  }

  .m-l-0-lg {
    margin-left: 0;
  }

  .m-r-0-lg {
    margin-right: 0;
  }

  .m-l-15-lg {
    margin-left: 15px;
  }

  .m-r-15-lg {
    margin-right: 15px;
  }

  .p-0-lg {
    padding: 0;
  }

  .p-lr-0-lg {
    padding-left: 0;
    padding-right: 0;
  }

  .p-lr-15-lg {
    padding-left: 15px;
    padding-right: 15px;
  }

  .p-l-0-lg {
    padding-left: 0;
  }

  .p-r-0-lg {
    padding-right: 0;
  }

  .p-l-15-lg {
    padding-left: 15px;
  }

  .p-r-15-lg {
    padding-right: 15px;
  }

  .w-full-lg {
    width: 100%;
  }

  /*---------------------------------------------*/


}


/*//////////////////////////////////////////////////////////////////
[ MD ]*/
@media (max-width: 768px) {
  .m-0-md {
    margin: 0;
  }

  .m-lr-0-md {
    margin-left: 0;
    margin-right: 0;
  }

  .m-lr-15-md {
    margin-left: 15px;
    margin-right: 15px;
  }

  .m-l-0-md {
    margin-left: 0;
  }

  .m-r-0-md {
    margin-right: 0;
  }

  .m-l-15-md {
    margin-left: 15px;
  }

  .m-r-15-md {
    margin-right: 15px;
  }

  .p-0-md {
    padding: 0;
  }

  .p-lr-0-md {
    padding-left: 0;
    padding-right: 0;
  }

  .p-lr-15-md {
    padding-left: 15px;
    padding-right: 15px;
  }

  .p-l-0-md {
    padding-left: 0;
  }

  .p-r-0-md {
    padding-right: 0;
  }

  .p-l-15-md {
    padding-left: 15px;
  }

  .p-r-15-md {
    padding-right: 15px;
  }

  .w-full-md {
    width: 100%;
  }

  /*---------------------------------------------*/

}


/*//////////////////////////////////////////////////////////////////
[ SM ]*/
@media (max-width: 576px) {
  .m-0-sm {
    margin: 0;
  }

  .m-lr-0-sm {
    margin-left: 0;
    margin-right: 0;
  }

  .m-lr-15-sm {
    margin-left: 15px;
    margin-right: 15px;
  }

  .m-l-0-sm {
    margin-left: 0;
  }

  .m-r-0-sm {
    margin-right: 0;
  }

  .m-l-15-sm {
    margin-left: 15px;
  }

  .m-r-15-sm {
    margin-right: 15px;
  }

  .p-0-sm {
    padding: 0;
  }

  .p-lr-0-sm {
    padding-left: 0;
    padding-right: 0;
  }

  .p-lr-15-sm {
    padding-left: 15px;
    padding-right: 15px;
  }

  .p-l-0-sm {
    padding-left: 0;
  }

  .p-r-0-sm {
    padding-right: 0;
  }

  .p-l-15-sm {
    padding-left: 15px;
  }

  .p-r-15-sm {
    padding-right: 15px;
  }

  .w-full-sm {
    width: 100%;
  }

  /*---------------------------------------------*/
  .respon1 {
    padding-top: 80px;
    padding-bottom: 100px;
  }

}


/*//////////////////////////////////////////////////////////////////
[ SSM ]*/
@media (max-width: 480px) {
  .m-0-ssm {
    margin: 0;
  }

  .m-lr-0-ssm {
    margin-left: 0;
    margin-right: 0;
  }

  .m-lr-15-ssm {
    margin-left: 15px;
    margin-right: 15px;
  }

  .m-l-0-ssm {
    margin-left: 0;
  }

  .m-r-0-ssm {
    margin-right: 0;
  }

  .m-l-15-ssm {
    margin-left: 15px;
  }

  .m-r-15-ssm {
    margin-right: 15px;
  }

  .p-0-ssm {
    padding: 0;
  }

  .p-lr-0-ssm {
    padding-left: 0;
    padding-right: 0;
  }

  .p-lr-15-ssm {
    padding-left: 15px;
    padding-right: 15px;
  }

  .p-l-0-ssm {
    padding-left: 0;
  }

  .p-r-0-ssm {
    padding-right: 0;
  }

  .p-l-15-ssm {
    padding-left: 15px;
  }

  .p-r-15-ssm {
    padding-right: 15px;
  }

  .w-full-ssm {
    width: 100%;
  }

  /*---------------------------------------------*/

}